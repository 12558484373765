"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppElement = exports.shuffleArray = void 0;
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}
exports.shuffleArray = shuffleArray;
class AppElement {
    element = document.createElement("div");
    Hide() {
        this.element.hidden = true;
    }
    Show() {
        this.element.hidden = false;
    }
    GetAsElement() {
        return this.element;
    }
    Remove(element) {
        if (!element) {
            return;
        }
        this.element.removeChild(element.GetAsElement());
    }
    Append(element) {
        this.GetAsElement().appendChild(element.GetAsElement());
    }
}
exports.AppElement = AppElement;
