"use strict";
// One game:
//   1. Spin the roulette
//   3. Spot the indigenous
//   3. Get your score
//   4. Back to main game, for next level
Object.defineProperty(exports, "__esModule", { value: true });
exports.LevelGame = void 0;
const util_js_1 = require("./util.js");
const level_pb_js_1 = require(".././protos/level_pb.js");
const grid_js_1 = require("./grid.js");
const selector_js_1 = require("./selector.js");
const constants_js_1 = require("./constants.js");
const validation_js_1 = require("./validation.js");
class LevelGame extends util_js_1.AppElement {
    level;
    journey;
    selector;
    validateElement;
    grid;
    // Stores all current level's trajectories, to avoid duplicates.
    // Includes the indigenous.
    trajectoriesRegistar = new Array();
    // Stores all positions, for all people, to avoid duplicates.
    // The map's key is the position's number:
    // 0 is initial position, 1 is the position after the first move, etc.
    // The value is an array of all Positions, which should all be unique.
    positionsRegistar = new Map();
    constructor(journey, level) {
        super();
        this.journey = journey;
        this.level = this.MergelevelAndJourney(level);
        (0, util_js_1.shuffleArray)(this.journey.symbols);
        this.selector = new selector_js_1.Selector(this.journey, this.level);
        this.Append(this.selector);
        this.grid = new grid_js_1.GridInst(this.journey, this.level);
        this.validateElement = new validation_js_1.ValidationElement();
        this.Append(this.validateElement);
    }
    MergelevelAndJourney(level) {
        const mergedLevel = new level_pb_js_1.Level().fromJsonString(level.toJsonString());
        if (mergedLevel.size === undefined) {
            if (this.journey.size === undefined) {
                throw new Error("Both journey and level has undefined sizes");
            }
            mergedLevel.size = this.journey.size;
        }
        if (mergedLevel.numMoves === undefined) {
            if (this.journey.numMoves === undefined) {
                throw new Error("Both journey and level has undefined numMoves");
            }
            mergedLevel.numMoves = this.journey.numMoves;
        }
        if (mergedLevel.numAliens === undefined) {
            if (this.journey.numAliens === undefined) {
                throw new Error("Both journey and level has undefined numAliens");
            }
            mergedLevel.numAliens = this.journey.numAliens;
        }
        if (mergedLevel.grid === undefined) {
            if (this.journey.grid === undefined) {
                throw new Error("Both journey and level has undefined numMoves");
            }
            mergedLevel.grid = this.journey.grid;
        }
        return mergedLevel;
    }
    async Start() {
        await this.WaitForUserSelection();
        return await this.BuildGridAndStartGame();
    }
    BuildGridAndStartGame() {
        return new Promise(async (resolve) => {
            this.Append(this.grid);
            const score = await this.grid.StartGame(this.level);
            this.level.score = score;
            this.grid.End();
            setTimeout(() => resolve(score), constants_js_1.TIMEOUT_BETWEEN_GAME_AND_SCOREBOARD);
        });
    }
    // Generate a person's initial state:
    // 1. Gets its symbol (aka color)
    // 2. If alien, will generate its moves then store them. If
    //    indigenous, will go straight to storing them.
    // 3. Will generate its starting position.
    GenerateInitialState(person, generateMoves = false) {
        person.color = this.GetNextColor();
        if (generateMoves) {
            this.GenerateMoves(person);
        }
        this.trajectoriesRegistar.push(person.trajectory);
        this.GenerateInitialPosition(person);
    }
    // Generates a person's random moves. If the moves were
    // already registered, recursively generate new ones.
    GenerateMoves(person) {
        if (person.trajectory === undefined) {
            person.trajectory = new level_pb_js_1.Trajectory();
        }
        for (var i = 0; i < this.level.numMoves; i++) {
            const randint = Math.floor(Math.random() * this.journey.allowedMoves.length);
            const randMove = this.journey.allowedMoves[randint];
            const move = new level_pb_js_1.Move().fromJsonString(randMove.toJsonString());
            person.trajectory.moves.push(move);
        }
        if (this.trajectoriesRegistar.length === 0) {
            throw new Error("Before generating random moves, " +
                "the indigenous move should be stored.");
        }
        for (const trajectory of this.trajectoriesRegistar) {
            if (trajectory.equals(person.trajectory)) {
                person.trajectory.moves = [];
                this.GenerateMoves(person);
                return;
            }
        }
    }
    GetNextColor() {
        const color = this.journey.symbols.pop();
        if (color === undefined) {
            console.log("No more colors");
            return "";
        }
        else {
            return color;
        }
    }
    GenerateInitialPosition(person) {
        const y_moves = new Array();
        const x_moves = new Array();
        if (!person.trajectory) {
            throw Error("Person has no trajectory: " + person);
        }
        for (const move of person.trajectory?.moves) {
            switch (move.direction) {
                case level_pb_js_1.MoveDirection.NO_MOVE:
                case level_pb_js_1.MoveDirection.UNSPECIFIED:
                case undefined:
                    break;
                case level_pb_js_1.MoveDirection.NORTH:
                    y_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.SOUTH:
                    y_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.WEST:
                    x_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.EAST:
                    x_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.SOUTH_EAST:
                    y_moves.push(move.direction);
                    x_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.SOUTH_WEST:
                    y_moves.push(move.direction);
                    x_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.NORTH_EAST:
                    y_moves.push(move.direction);
                    x_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.NORTH_WEST:
                    y_moves.push(move.direction);
                    x_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_NORTH:
                    y_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_SOUTH:
                    y_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_WEST:
                    x_moves.push(move.direction);
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_EAST:
                    x_moves.push(move.direction);
                    break;
                default:
                    throw Error("Unknown move direction: " + move.direction);
            }
        }
        if (person.position === undefined) {
            person.position = new level_pb_js_1.Position();
        }
        person.position.xOffset = GetOffset(this.level.grid?.width, x_moves, [level_pb_js_1.MoveDirection.WEST, level_pb_js_1.MoveDirection.NORTH_WEST, level_pb_js_1.MoveDirection.SOUTH_WEST], [level_pb_js_1.MoveDirection.EAST, level_pb_js_1.MoveDirection.NORTH_EAST, level_pb_js_1.MoveDirection.SOUTH_EAST], [level_pb_js_1.MoveDirection.DOUBLE_WEST], [level_pb_js_1.MoveDirection.DOUBLE_EAST]);
        person.position.yOffset = GetOffset(this.level.grid?.width, y_moves, [level_pb_js_1.MoveDirection.SOUTH, level_pb_js_1.MoveDirection.SOUTH_EAST, level_pb_js_1.MoveDirection.SOUTH_WEST], [level_pb_js_1.MoveDirection.NORTH, level_pb_js_1.MoveDirection.NORTH_EAST, level_pb_js_1.MoveDirection.NORTH_WEST], [level_pb_js_1.MoveDirection.DOUBLE_SOUTH], [level_pb_js_1.MoveDirection.DOUBLE_NORTH]);
        try {
            this.registerPosition(person);
        }
        catch {
            delete person.position;
            console.log("unlucky-position");
            this.GenerateInitialPosition(person);
        }
    }
    registerPosition(person) {
        const initialPosition = person.position;
        if (!this.positionsRegistar.has(0)) {
            this.positionsRegistar.set(0, new Array());
        }
        for (const registeredPosition of this.positionsRegistar.get(0)) {
            if (initialPosition.equals(registeredPosition)) {
                throw new Error();
            }
        }
        this.positionsRegistar.get(0)?.push(initialPosition);
        var nextMove = 1;
        var currentPosition = initialPosition;
        for (const move of person.trajectory?.moves) {
            const position = new level_pb_js_1.Position();
            switch (move.direction) {
                case undefined:
                case level_pb_js_1.MoveDirection.UNSPECIFIED:
                case level_pb_js_1.MoveDirection.NO_MOVE:
                    position.xOffset = currentPosition.xOffset;
                    position.yOffset = currentPosition.yOffset;
                    break;
                case level_pb_js_1.MoveDirection.NORTH:
                    position.xOffset = currentPosition.xOffset;
                    position.yOffset = currentPosition.yOffset + 1;
                    break;
                case level_pb_js_1.MoveDirection.SOUTH:
                    position.xOffset = currentPosition.xOffset;
                    position.yOffset = currentPosition.yOffset - 1;
                    break;
                case level_pb_js_1.MoveDirection.EAST:
                    position.xOffset = currentPosition.xOffset + 1;
                    position.yOffset = currentPosition.yOffset;
                    break;
                case level_pb_js_1.MoveDirection.WEST:
                    position.xOffset = currentPosition.xOffset - 1;
                    position.yOffset = currentPosition.yOffset;
                    break;
                case level_pb_js_1.MoveDirection.SOUTH_EAST:
                    position.xOffset = currentPosition.xOffset + 1;
                    position.yOffset = currentPosition.yOffset - 1;
                    break;
                case level_pb_js_1.MoveDirection.SOUTH_WEST:
                    position.xOffset = currentPosition.xOffset - 1;
                    position.yOffset = currentPosition.yOffset - 1;
                    break;
                case level_pb_js_1.MoveDirection.NORTH_EAST:
                    position.xOffset = currentPosition.xOffset + 1;
                    position.yOffset = currentPosition.yOffset + 1;
                    break;
                case level_pb_js_1.MoveDirection.NORTH_WEST:
                    position.xOffset = currentPosition.xOffset - 1;
                    position.yOffset = currentPosition.yOffset + 1;
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_NORTH:
                    position.xOffset = currentPosition.xOffset;
                    position.yOffset = currentPosition.yOffset + 2;
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_SOUTH:
                    position.xOffset = currentPosition.xOffset;
                    position.yOffset = currentPosition.yOffset - 2;
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_EAST:
                    position.xOffset = currentPosition.xOffset + 2;
                    position.yOffset = currentPosition.yOffset;
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_WEST:
                    position.xOffset = currentPosition.xOffset - 2;
                    position.yOffset = currentPosition.yOffset;
                    break;
                default:
                    throw Error("Unknown MoveDirection: " + move.direction);
            }
            if (!this.positionsRegistar.has(nextMove)) {
                this.positionsRegistar.set(nextMove, new Array());
            }
            for (const registeredPosition of this.positionsRegistar.get(nextMove)) {
                if (registeredPosition.equals(position)) {
                    throw new Error();
                }
            }
            this.positionsRegistar.get(nextMove)?.push(position);
            nextMove += 1;
            currentPosition = position;
        }
    }
    GenerateAliensAndStates() {
        const grid = this.level.grid;
        grid.height = this.level.size;
        grid.width = this.level.size;
        this.GenerateInitialState(grid.indigenous);
        this.AddAliens();
    }
    AddAliens() {
        for (var i = 0; i < this.level.numAliens; i++) {
            const alien = new level_pb_js_1.Person();
            this.level.grid?.aliens.push(alien);
            alien.type = level_pb_js_1.PersonType.ALIEN;
            this.GenerateInitialState(alien, true);
        }
    }
    async WaitForUserSelection() {
        return new Promise(async (resolve) => {
            await this.validateElement.listenForSpinClick();
            await this.selector.TriggerRoll();
            await this.validateElement.listenForSpotClick();
            this.GenerateAliensAndStates();
            this.validateElement.Hide();
            resolve();
        });
    }
}
exports.LevelGame = LevelGame;
function GetOffset(length, moves, backwardMoves, forwardMoves, doubleBackWardMoves, doubleForwardMoves) {
    var currentPosition = 0;
    var backwardsMost = 0;
    var forwardsMost = 0;
    for (const move of moves) {
        if (forwardMoves.includes(move)) {
            currentPosition += 1;
            forwardsMost = Math.max(currentPosition, forwardsMost);
            backwardsMost = Math.min(currentPosition, backwardsMost);
        }
        if (backwardMoves.includes(move)) {
            currentPosition -= 1;
            forwardsMost = Math.max(currentPosition, forwardsMost);
            backwardsMost = Math.min(currentPosition, backwardsMost);
        }
        if (doubleForwardMoves.includes(move)) {
            currentPosition += 2;
            forwardsMost = Math.max(currentPosition, forwardsMost);
            backwardsMost = Math.min(currentPosition, backwardsMost);
        }
        if (doubleBackWardMoves.includes(move)) {
            currentPosition -= 2;
            forwardsMost = Math.max(currentPosition, forwardsMost);
            backwardsMost = Math.min(currentPosition, backwardsMost);
        }
    }
    const max = length - Math.max(0, forwardsMost) + 1;
    const min = Math.abs(backwardsMost);
    const value = Math.floor(Math.random() * (max - min) + min);
    return value;
}
