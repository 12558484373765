"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Option = exports.Selector = exports.GrowIcons = exports.SpinIcons = exports.DirectionIcons = void 0;
const util_js_1 = require("./util.js");
const level_pb_js_1 = require(".././protos/level_pb.js");
const emoji = __importStar(require("../emojis.js"));
// Map between MoveDirection and Material Icon name.
exports.DirectionIcons = new Map([
    [level_pb_js_1.MoveDirection.NO_MOVE, emoji.PROHIBITED],
    [level_pb_js_1.MoveDirection.NORTH, emoji.UP_ARROW],
    [level_pb_js_1.MoveDirection.SOUTH, emoji.DOWN_ARROW],
    [level_pb_js_1.MoveDirection.WEST, emoji.LEFT_ARROW],
    [level_pb_js_1.MoveDirection.EAST, emoji.RIGHT_ARROW],
    [level_pb_js_1.MoveDirection.SOUTH_EAST, emoji.DOWN_RIGHT_ARROW],
    [level_pb_js_1.MoveDirection.SOUTH_WEST, emoji.DOWN_LEFT_ARROW],
    [level_pb_js_1.MoveDirection.NORTH_WEST, emoji.UP_LEFT_ARROW],
    [level_pb_js_1.MoveDirection.NORTH_EAST, emoji.UP_RIGHT_ARROW],
    [level_pb_js_1.MoveDirection.DOUBLE_NORTH, emoji.DOUBLE_UP_ARROW],
    [level_pb_js_1.MoveDirection.DOUBLE_SOUTH, emoji.DOUBLE_DOWN_ARROW],
    [level_pb_js_1.MoveDirection.DOUBLE_WEST, emoji.DOUBLE_LEFT_ARROW],
    [level_pb_js_1.MoveDirection.DOUBLE_EAST, emoji.DOUBLE_RIGHT_ARROW],
    [level_pb_js_1.MoveDirection.UNSPECIFIED, emoji.QUESTION_MARK],
]);
exports.SpinIcons = new Map([
    [level_pb_js_1.MoveSpin.NO_SPIN, emoji.CROSS_MARK],
    [level_pb_js_1.MoveSpin.HALF_CLOCKWISE, emoji.RIGHT_ARROW_CURVING_LEFT],
    [level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE, emoji.RIGHT_ARROW_CURVING_RIGHT],
]);
exports.GrowIcons = new Map([
    [level_pb_js_1.MoveGrow.NO_GROW, emoji.CROSS_MARK],
    [level_pb_js_1.MoveGrow.ENLARGE, emoji.OPEN_HANDS],
    [level_pb_js_1.MoveGrow.SHRINK, emoji.PINCHED_HANDS],
]);
// Instead of the user clicking on several options, a RandomSelector is
// one where an Option is selected at random as the user clicks on it.
class Selector extends util_js_1.AppElement {
    journey;
    level;
    options = new Array();
    selections = new Array();
    constructor(journey, level) {
        super();
        this.journey = journey;
        this.level = level;
        this.element.setAttribute("id", "selection");
        this.element.classList.add("horizontalChoices");
        this.GenerateSelectionElement();
    }
    GenerateSelectionElement() {
        var maxIterations = 20;
        for (var i = 0; i < this.level.numMoves; i++) {
            // Copy of this.journey.allowedMoves so that each copy is independent.
            const option = new Option(this.journey.clone().allowedMoves, maxIterations);
            maxIterations = maxIterations + 3;
            this.Append(option);
            this.options.push(option);
        }
    }
    TriggerRoll() {
        return new Promise(async (resolve) => {
            const promises = [];
            for (const option of this.options) {
                promises.push(option.triggerApplication());
            }
            const moves = await Promise.all(promises);
            for (const move of moves) {
                this.level.grid?.indigenous?.trajectory?.moves.push(move);
            }
            resolve();
        });
    }
}
exports.Selector = Selector;
class Option extends util_js_1.AppElement {
    move = new level_pb_js_1.Move();
    allowedMoves;
    duration = Math.floor(Math.random() * 5) + 20;
    numIteration = 0;
    maxIterations;
    event = new CustomEvent("animationDone");
    numMovesInDom = 4;
    NthElementShow = 2;
    // Each move's number of dimensions.
    // If only direction: MOVE_DIRECTION_NORTH, then 1.
    // If direction: MOVE_DIRECTION_NORTH spin: MOVE_SPIN_HALF_CLOCKWISE then 2.
    numDimensions = 0;
    constructor(allowedMoves, maxIterations) {
        super();
        this.allowedMoves = allowedMoves;
        this.maxIterations = maxIterations;
        (0, util_js_1.shuffleArray)(this.allowedMoves);
        this.element.classList.add("notSelectable");
        this.element.setAttribute("tabindex", "0");
        this.createElements();
    }
    createElements() {
        for (var i = 0; i < this.numMovesInDom; i++) {
            this.element.appendChild(this.createElement(this.getNextMove()));
        }
        // Insert a dummy "?" element which will be shown as the starting
        // roll element. This is does not belong to allowdMoved, it will be
        // discarded when moveToFrontAnd() is called.
        this.element.insertBefore(this.createElement(new level_pb_js_1.Move().fromJson({
            direction: level_pb_js_1.MoveDirection.UNSPECIFIED,
        })), this.element.children[this.NthElementShow]);
        // Also remove the first element so that we always only have exactly
        // this.numMovesInDom in the container.
        if (this.element.firstElementChild) {
            this.element.removeChild(this.element.firstElementChild);
        }
    }
    createElement(move) {
        const element = document.createElement("div");
        element.style.transform = "translateY(50%)";
        this.setText(element, move);
        return element;
    }
    triggerApplication() {
        this.animate(this.duration);
        return new Promise((resolve) => {
            this.element.addEventListener("animationDone", (event) => {
                resolve(this.move);
            }, false);
        });
    }
    animate(duration) {
        const frames = new Array();
        frames.push({
            transform: "translateY(0)",
            offset: 0,
        });
        frames.push({
            transform: "translateY(calc(var(--cell-size) * -1))",
            offset: 1,
        });
        const keyframes = new KeyframeEffect(this.element, frames, {
            duration: duration,
            fill: "forwards",
        });
        const animation = new Animation(keyframes, document.timeline);
        animation.onfinish = () => {
            this.moveToFrontAnd();
        };
        animation.play();
    }
    getNextMove() {
        const first = this.allowedMoves.shift();
        if (first) {
            this.allowedMoves.push(first);
            return first;
        }
        throw Error("No next option found");
    }
    moveToFrontAnd() {
        if (this.element.firstChild == null) {
            return;
        }
        if (this.element.lastChild == null) {
            return;
        }
        if (this.numIteration > this.maxIterations) {
            this.move =
                this.allowedMoves[this.allowedMoves.length - this.NthElementShow];
            this.element.dispatchEvent(this.event);
            return;
        }
        this.numIteration++;
        this.element.appendChild(this.createElement(this.getNextMove()));
        this.element.removeChild(this.element.firstChild);
        this.duration = this.duration * 1.1;
        this.animate(this.duration);
    }
    setText(element, move) {
        element.textContent = "";
        if (move.direction !== undefined) {
            const image = document.createElement("img");
            image.src = exports.DirectionIcons.get(move.direction) || "";
            image.setAttribute("alt", level_pb_js_1.MoveDirection[move.direction]);
            element.appendChild(image);
        }
        if (move.spin !== undefined) {
            const image = document.createElement("img");
            image.src = exports.SpinIcons.get(move.spin) || "";
            image.setAttribute("alt", level_pb_js_1.MoveDirection[move.spin]);
            element.appendChild(image);
        }
        if (move.grow !== undefined) {
            const image = document.createElement("img");
            image.src = exports.GrowIcons.get(move.grow) || "";
            image.setAttribute("alt", level_pb_js_1.MoveDirection[move.grow]);
            element.appendChild(image);
        }
    }
}
exports.Option = Option;
