"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountDown = void 0;
const constants_js_1 = require("./constants.js");
const util_js_1 = require("./util.js");
const emoji = __importStar(require("../emojis.js"));
class CountDown extends util_js_1.AppElement {
    numStars = constants_js_1.TOTAL_NUM_STARS;
    stars = [];
    nextStar;
    fadeOutAnimation = new Animation();
    constructor() {
        super();
        this.element.classList.add("horizontalChoices");
        this.element.classList.add("bottomBar");
        for (var i = 0; i < this.numStars; i++) {
            const star = document.createElement("img");
            star.src = emoji.GLOWING_STAR;
            star.classList.add("active");
            star.classList.add("star");
            this.element.appendChild(star);
            this.stars.push(star);
        }
        this.nextStar = this.stars[this.numStars - 1];
    }
    CancelAnimationAndRestoreStar() {
        if (this.fadeOutAnimation.playState === "running") {
            const star = this.stars[this.numStars - 1];
            star.src = emoji.GLOWING_STAR;
            this.fadeOutAnimation.cancel();
        }
    }
    RemoveStar(playbackRate) {
        return new Promise((resolve) => {
            this.fadeOutAnimation = this.FadeOutStar(this.nextStar);
            this.nextStar.src = emoji.STAR;
            this.fadeOutAnimation.playbackRate = playbackRate;
            this.fadeOutAnimation.onfinish = (event) => {
                this.nextStar.classList.remove("active");
                this.nextStar.classList.add("inactive");
                this.numStars -= 1;
                this.nextStar = this.stars[this.numStars - 1];
                resolve(this.numStars > 0);
            };
            this.fadeOutAnimation.play();
        });
    }
    FadeOutStar(star) {
        const keyframes = new KeyframeEffect(star, [
            {
                offset: 0,
                transform: "scale(1, 1)",
            },
            {
                offset: 0.5,
                transform: "scale(2, 2) rotate(0.5turn)",
            },
            {
                offset: 1,
                transform: "scale(1, 1) rotate(1turn)",
            },
        ], {
            duration: constants_js_1.FADE_IN_OUT_DURATION_MS + constants_js_1.PAUSE_BETWEEN_ANIMATION_CYCLES,
            fill: "forwards",
        });
        return new Animation(keyframes);
    }
}
exports.CountDown = CountDown;
