"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoSpotItApp = void 0;
const util_js_1 = require("./src/util.js");
const level_pb_js_1 = require("./protos/level_pb.js");
const scoreboard_js_1 = require("./src/scoreboard.js");
require("./static/style.css");
const level_game_js_1 = require("./src/level_game.js");
const storer_js_1 = require("./src/storer.js");
class GoSpotItApp extends util_js_1.AppElement {
    storer;
    levelGame = null;
    journey = null;
    level = null;
    scoreboard = null;
    outContainer = document.body;
    constructor() {
        super();
        this.storer = new storer_js_1.GameStorer();
        this.element.setAttribute("id", "selectorContainer");
        this.element.classList.add("banner");
        this.outContainer.appendChild(this.element);
    }
    async StartNewGameLevel() {
        this.cleanup();
        this.storer.StoreGameAsLocalStorage();
        this.journey = new level_pb_js_1.Journey().fromJsonString(this.storer.getJourney().toJsonString());
        this.level = new level_pb_js_1.Level().fromJsonString(this.storer
            .getLevel(this.journey, this.journey.nextLevel || 1)
            .toJsonString());
        this.levelGame = new level_game_js_1.LevelGame(this.journey, this.level);
        this.Append(this.levelGame);
        this.scoreboard = new scoreboard_js_1.ScoreBoard(this.storer);
        this.Append(this.scoreboard);
        const score = await this.levelGame.Start();
        this.storer.storeScore(score);
        this.UpdateAndShowScoreBoard(score);
        const action = await this.scoreboard.waitforUserSelection(this.journey.number);
        this.performNextLevelAction(action);
    }
    cleanup() {
        this.Remove(this.levelGame);
        this.Remove(this.scoreboard);
    }
    UpdateAndShowScoreBoard(score) {
        if (!this.scoreboard) {
            return;
        }
        if (!this.journey) {
            return;
        }
        if (!this.level) {
            return;
        }
        if (!score) {
            console.warn("Fallback to score 0");
            score = 0;
        }
        this.levelGame?.Hide();
        this.storer.getLevel(this.storer.getJourney(), this.journey.nextLevel || 1).score = score;
        this.storer.StoreGameAsLocalStorage();
        this.scoreboard.Update(this.journey.number);
        this.scoreboard.Show();
    }
    performNextLevelAction(action) {
        switch (action) {
            case level_pb_js_1.NextLevelAction.RESTART_GAME:
                this.storer.RestartGame();
                break;
            case level_pb_js_1.NextLevelAction.RESTART_JOURNEY:
                this.storer.RestartJourney();
                break;
            case level_pb_js_1.NextLevelAction.TRIGGER_NEXT_LEVEL:
                this.triggerNextLevel();
                break;
            default:
                throw Error("Unknown level action: " + action);
        }
        this.StartNewGameLevel();
    }
    triggerNextLevel() {
        if (!this.journey) {
            return;
        }
        if (!this.journey.number) {
            throw new Error("Journey does not have a number");
        }
        var gameJourney = this.storer.GetJourneyFromNumber(this.journey.number);
        if (!gameJourney.nextLevel) {
            gameJourney.nextLevel = 1;
        }
        if (gameJourney.nextLevel >= gameJourney.levels.length) {
            this.storer.IncrementJourney();
            return;
        }
        gameJourney.nextLevel += 1;
    }
}
exports.GoSpotItApp = GoSpotItApp;
function Init() {
    const app = new GoSpotItApp();
    app.StartNewGameLevel();
}
Init();
