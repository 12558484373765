"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoardBead = void 0;
const level_pb_js_1 = require(".././protos/level_pb.js");
const constants_js_1 = require("./constants.js");
const util_js_1 = require("./util.js");
class Bead extends util_js_1.AppElement {
    person;
    level;
    constructor(person, level) {
        super();
        this.person = person;
        this.level = level;
        this.element.classList.add("bead");
        this.element.classList.add("active");
        const image = document.createElement("img");
        image.src = this.person.color;
        this.element.appendChild(image);
    }
    WaitForClick() {
        return new Promise((resolve) => {
            this.element.addEventListener(constants_js_1.MOUSEDOWN, (_) => {
                resolve(this.person.type || level_pb_js_1.PersonType.UNSPECIFIED);
            });
        });
    }
    RenderInactive() {
        this.element.classList.remove("active");
        this.element.classList.add("inactive");
    }
}
// A bead that is in the board, moving.
class BoardBead extends Bead {
    movementIncrement;
    avatarBead;
    animationOffset;
    fadeIn = new Animation();
    mainAnimation = new Animation();
    fadeOut = new Animation();
    fadeInFrames = new Array();
    mainAnimationFrames = new Array();
    fadeOutFrames = new Array();
    constructor(person, level) {
        super(person, level);
        this.animationOffset = 0;
        this.movementIncrement = 100 / this.level.grid?.width;
        this.element.classList.add("activeBead");
        this.animationOffset = 1 / this.person.trajectory?.moves?.length;
        this.element.style.bottom =
            (this.movementIncrement * this.person.position?.yOffset).toString() +
                "%";
        this.element.style.left =
            (this.movementIncrement * this.person.position?.xOffset).toString() +
                "%";
        this.avatarBead = new AvatarBead(this.person, this.level);
        this.fadeInFrames = this.generateFadeInFrames();
        this.mainAnimationFrames = this.generateMainAnimationFrames();
        this.fadeOutFrames = this.generateFadeOutFrames();
    }
    GetAvatarBead() {
        return this.avatarBead;
    }
    initAndWaitForUserSelection() {
        return new Promise(async (resolve) => {
            const promises = [];
            promises.push(this.WaitForClick());
            promises.push(this.avatarBead?.WaitForClick());
            const type = await Promise.any(promises);
            this.Reveal();
            resolve(type);
        });
    }
    Reveal() {
        if (this.person.type === level_pb_js_1.PersonType.ALIEN) {
            this.RenderInactive();
            this.avatarBead.RenderInactive();
        }
    }
    generateFadeInFrames() {
        var bottom = this.movementIncrement * this.person.position?.yOffset;
        var left = this.movementIncrement * this.person.position?.xOffset;
        const frames = new Array();
        frames.push({
            opacity: "0",
            offset: 0,
            bottom: bottom.toString() + "%",
            left: left.toString() + "%",
            transform: "translate(-50%,50%) rotate(0deg)",
        });
        frames.push({
            opacity: "1",
            offset: 1,
            bottom: bottom.toString() + "%",
            left: left.toString() + "%",
            transform: "translate(-50%,50%) rotate(0deg)",
        });
        return frames;
    }
    GenerateFadeInAnimation() {
        const keyframes = new KeyframeEffect(this.element, this.fadeInFrames, {
            duration: constants_js_1.FADE_IN_OUT_DURATION_MS,
            fill: "forwards",
            easing: "ease-in-out",
        });
        const animation = new Animation(keyframes, document.timeline);
        return animation;
    }
    generateFadeOutFrames() {
        const frames = new Array();
        frames.push({
            opacity: "1",
            offset: 0,
        });
        frames.push({
            opacity: "0",
            offset: 1,
        });
        return frames;
    }
    GenerateFadeOutAnimation() {
        const keyframes = new KeyframeEffect(this.element, this.fadeOutFrames, {
            duration: constants_js_1.FADE_IN_OUT_DURATION_MS,
            fill: "forwards",
            easing: "ease-in-out",
        });
        const animation = new Animation(keyframes, document.timeline);
        return animation;
    }
    generateMainAnimationFrames() {
        var bottom = this.movementIncrement * this.person.position?.yOffset;
        var left = this.movementIncrement * this.person.position?.xOffset;
        var rotation = 0;
        var scale = 1;
        var animationOffset = 0;
        const frames = new Array();
        frames.push({
            offset: 0,
            bottom: bottom.toString() + "%",
            fontSize: "var(--cell-size)",
            left: left.toString() + "%",
            transform: "translate(-50%,50%) rotate(" + rotation + "deg)",
            easing: "ease-in-out",
        });
        for (const move of this.person.trajectory?.moves) {
            animationOffset = animationOffset + this.animationOffset;
            switch (move.direction) {
                case level_pb_js_1.MoveDirection.NO_MOVE:
                case level_pb_js_1.MoveDirection.UNSPECIFIED:
                case undefined:
                    break;
                case level_pb_js_1.MoveDirection.NORTH:
                    bottom = bottom + this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.SOUTH:
                    bottom = bottom - this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.WEST:
                    left = left - this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.EAST:
                    left = left + this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.SOUTH_EAST:
                    left = left + this.movementIncrement;
                    bottom = bottom - this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.SOUTH_WEST:
                    left = left - this.movementIncrement;
                    bottom = bottom - this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.NORTH_EAST:
                    left = left + this.movementIncrement;
                    bottom = bottom + this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.NORTH_WEST:
                    left = left - this.movementIncrement;
                    bottom = bottom + this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_NORTH:
                    bottom = bottom + 2 * this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_SOUTH:
                    bottom = bottom - 2 * this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_WEST:
                    left = left - 2 * this.movementIncrement;
                    break;
                case level_pb_js_1.MoveDirection.DOUBLE_EAST:
                    left = left + 2 * this.movementIncrement;
                    break;
                default:
                    throw Error("unknown code: " + move.direction);
            }
            switch (move.spin) {
                case level_pb_js_1.MoveSpin.NO_SPIN:
                case level_pb_js_1.MoveSpin.UNSPECIFIED:
                case undefined:
                    break;
                case level_pb_js_1.MoveSpin.HALF_CLOCKWISE:
                    rotation = rotation + 180;
                    break;
                case level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE:
                    rotation = rotation - 180;
                    break;
                default:
                    throw Error("Unknown spin code: " + move.spin);
            }
            switch (move.grow) {
                case level_pb_js_1.MoveGrow.NO_GROW:
                case level_pb_js_1.MoveGrow.UNSPECIFIED:
                case undefined:
                    break;
                case level_pb_js_1.MoveGrow.ENLARGE:
                    scale = scale * 1.5;
                    break;
                case level_pb_js_1.MoveGrow.SHRINK:
                    scale = scale / 1.5;
                    break;
                default:
                    throw Error("Unknown grow code: " + move.grow);
            }
            frames.push({
                offset: Math.min(animationOffset, 1),
                bottom: bottom.toString() + "%",
                left: left.toString() + "%",
                transform: "translate(-50%,50%) rotate(" +
                    rotation +
                    "deg) scale(" +
                    scale +
                    ")",
                easing: "ease-in-out",
            });
        }
        return frames;
    }
    GenerateMainAnimation() {
        const keyframes = new KeyframeEffect(this.element, this.mainAnimationFrames, {
            duration: (this.level.timePerMoveMs || 200) * this.level.numMoves,
            fill: "forwards",
            delay: constants_js_1.DELAY_BETWEEN_FADE_IN_AND_MAIN_ANIMATION_MS,
        });
        const animation = new Animation(keyframes);
        return animation;
    }
    animateElement() {
        var iterationNum = 0;
        var playbackRate = 1;
        this.fadeIn = this.GenerateFadeInAnimation();
        this.mainAnimation = this.GenerateMainAnimation();
        this.fadeOut = this.GenerateFadeOutAnimation();
        this.fadeIn.onfinish = (event) => {
            try {
                this.fadeIn.commitStyles();
            }
            catch { }
            this.fadeIn.cancel();
            this.mainAnimation.play();
        };
        this.mainAnimation.onfinish = (event) => {
            try {
                this.mainAnimation.commitStyles();
            }
            catch { }
            this.mainAnimation.cancel();
            const payload = {
                iterationNum: iterationNum,
                playbackRate: playbackRate,
            };
            this.dispatchEndOfCycleEvent(payload);
            this.fadeOut.play();
        };
        this.fadeOut.onfinish = (event) => {
            try {
                this.fadeOut.commitStyles();
            }
            catch { }
            this.fadeOut.cancel();
            iterationNum += 1;
            playbackRate = playbackRate * constants_js_1.RATE_OF_ANIMATION_SLOWDOWN;
            this.fadeIn.updatePlaybackRate(playbackRate);
            this.mainAnimation.updatePlaybackRate(playbackRate);
            this.fadeOut.updatePlaybackRate(playbackRate);
            setTimeout(() => this.fadeIn.play(), constants_js_1.PAUSE_BETWEEN_ANIMATION_CYCLES);
        };
        this.fadeIn.play();
    }
    stopAnimation() {
        this.fadeIn.onfinish = null;
        this.mainAnimation.onfinish = null;
        this.fadeOut.onfinish = null;
    }
    dispatchEndOfCycleEvent(payload) {
        const endOfCycleEvent = new MessageEvent("message", {
            data: {
                iterationNum: payload.iterationNum,
                playbackRate: payload.playbackRate,
            },
        });
        window.dispatchEvent(endOfCycleEvent);
    }
}
exports.BoardBead = BoardBead;
class AvatarBead extends Bead {
    constructor(person, level) {
        super(person, level);
        this.element.classList.add("avatarBead");
    }
}
