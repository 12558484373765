"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TIMEOUT_BETWEEN_GAME_AND_SCOREBOARD = exports.MOUSEDOWN = exports.TOTAL_NUM_STARS = exports.PAUSE_BETWEEN_ANIMATION_CYCLES = exports.DELAY_BETWEEN_FADE_IN_AND_MAIN_ANIMATION_MS = exports.FADE_IN_OUT_DURATION_MS = exports.RATE_OF_ANIMATION_SLOWDOWN = void 0;
exports.RATE_OF_ANIMATION_SLOWDOWN = 0.8;
exports.FADE_IN_OUT_DURATION_MS = 100;
exports.DELAY_BETWEEN_FADE_IN_AND_MAIN_ANIMATION_MS = 100;
exports.PAUSE_BETWEEN_ANIMATION_CYCLES = 200;
exports.TOTAL_NUM_STARS = 5;
exports.MOUSEDOWN = "mousedown";
exports.TIMEOUT_BETWEEN_GAME_AND_SCOREBOARD = 3000;
