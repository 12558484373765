"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameStorer = void 0;
const levels_js_1 = require("./levels.js");
const level_pb_js_1 = require(".././protos/level_pb.js");
class GameStorer {
    game;
    constructor() {
        this.game = this.getGame();
    }
    GetJourneys() {
        return this.game.journeys;
    }
    getGame() {
        const storedGameStr = localStorage.getItem("game");
        if (!storedGameStr) {
            return levels_js_1.GAME;
        }
        const storedGame = new level_pb_js_1.Game().fromJsonString(storedGameStr, {
            ignoreUnknownFields: true,
        });
        levels_js_1.GAME.nextJourney = storedGame.nextJourney || 1;
        console.log("J=" + levels_js_1.GAME.nextJourney);
        var haveSeenNextJourney = false;
        for (const journey of levels_js_1.GAME.journeys) {
            if (journey.number === undefined) {
                throw new Error("All journeys should have a number: " + journey.toJsonString);
            }
            if (journey.number === levels_js_1.GAME.nextJourney) {
                haveSeenNextJourney = true;
            }
            for (const storedJourney of storedGame.journeys) {
                if (journey.number !== storedJourney.number) {
                    continue;
                }
                journey.nextLevel = storedJourney.nextLevel || 1;
                var haveSeenLevel = false;
                for (const level of journey.levels) {
                    if (level.number === undefined) {
                        throw new Error("All levels should have a number: " + level.toJsonString);
                    }
                    if (level.number === journey.nextLevel) {
                        haveSeenLevel = true;
                    }
                    if (level.number > journey.nextLevel) {
                        level.score = undefined;
                        continue;
                    }
                    for (const storedLevel of storedJourney.levels) {
                        if (level.number !== storedLevel.number) {
                            continue;
                        }
                        level.score = storedLevel.score;
                    }
                }
                if (!haveSeenLevel) {
                    console.warn("Found corrupted next Level: "
                        + storedJourney.nextLevel
                        + ". Fallback to 1");
                    journey.nextLevel = 1;
                }
            }
        }
        if (!haveSeenNextJourney) {
            console.warn("Found corrupted next Journey: " +
                storedGame.nextJourney +
                ". Fallback to 1.");
            levels_js_1.GAME.nextJourney = 1;
        }
        return levels_js_1.GAME;
    }
    getJourney() {
        var fallbackJourney = new level_pb_js_1.Journey();
        for (const journey of this.game.journeys) {
            if (journey.number === this.game.nextJourney) {
                return journey;
            }
        }
        return fallbackJourney;
    }
    getLevel(journey, levelNumber) {
        for (const level of journey.levels) {
            if (level.number === levelNumber) {
                return level;
            }
        }
        throw new Error("Journey is completed. Requested: " + levelNumber);
    }
    getScore(journeyNum, levelNum) {
        for (const journey of this.game.journeys) {
            if (journey.number !== journeyNum) {
                continue;
            }
            for (const level of journey.levels) {
                if (level.number !== levelNum) {
                    continue;
                }
                return level.score;
            }
        }
    }
    StoreGameAsLocalStorage() {
        localStorage.setItem("game", this.game.toJsonString());
    }
    storeScore(score) {
        for (const journey of this.game.journeys) {
            if (journey.number !== this.game.nextJourney) {
                continue;
            }
            for (const level of journey.levels) {
                if (level.number !== journey.nextLevel) {
                    continue;
                }
                level.score = score;
                console.log("Set score " + score + " to J" + journey.number + "L" + level.number);
            }
        }
        this.StoreGameAsLocalStorage();
    }
    RestartJourney() {
        // Scracth all scores from current journey
        for (const journey of this.game.journeys) {
            if (journey.number !== this.game.nextJourney) {
                continue;
            }
            this.resetJourney(journey);
        }
    }
    RestartGame() {
        // Scracth all scores from current journey
        for (const journey of this.game.journeys) {
            this.resetJourney(journey);
        }
        this.game.nextJourney = 1;
    }
    GetJourneyFromNumber(num) {
        for (const journey of this.game.journeys) {
            if (journey.number === num) {
                return journey;
            }
        }
        throw new Error("Could not find journey #" + num);
    }
    IncrementJourney() {
        this.game.nextJourney += 1;
        console.log("incremented next journey: " + this.game.nextJourney);
    }
    resetJourney(journey) {
        for (const level of journey.levels) {
            level.score = undefined;
        }
        journey.nextLevel = 1;
    }
}
exports.GameStorer = GameStorer;
