"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GridInst = void 0;
const bead_js_1 = require("./bead.js");
const level_pb_js_1 = require(".././protos/level_pb.js");
const countdown_js_1 = require("./countdown.js");
const util_js_1 = require("./util.js");
class InnerContainer extends util_js_1.AppElement {
    constructor() {
        super();
        this.element.setAttribute("id", "innerContainer");
    }
}
class OuterContainer extends util_js_1.AppElement {
    constructor() {
        super();
        this.element.setAttribute("id", "outerContainer");
    }
}
class BeadsContainer extends util_js_1.AppElement {
    constructor() {
        super();
        this.element.setAttribute("id", "avatarBeadsContainer");
        this.element.classList.add("flexBox");
        this.element.classList.add("overFlowWrap");
    }
}
class GridInst extends util_js_1.AppElement {
    journey;
    level;
    countdown;
    innerContainer = new InnerContainer();
    outerContainer = new OuterContainer();
    avatarBeadsContainer = new BeadsContainer();
    beads = [];
    constructor(journey, level) {
        super();
        this.journey = journey;
        this.level = level;
        this.outerContainer.Append(this.innerContainer);
        this.Append(this.outerContainer);
        this.Append(this.avatarBeadsContainer);
        this.countdown = this.AppendCountDown();
    }
    End() {
        for (const bead of this.beads) {
            bead.Reveal();
        }
    }
    StartGame(level) {
        this.level = level;
        if (!this.level.grid) {
            throw Error("No grid found in level: " + this.level);
        }
        for (const alien of this.level.grid.aliens) {
            this.AppendPerson(alien);
        }
        if (!this.level.grid.indigenous) {
            throw Error("No indigenous found in grid: " + this.level.grid);
        }
        this.AppendPerson(this.level.grid.indigenous);
        this.AppendAvatarBeads();
        const seenCycles = new Set();
        return new Promise(async (resolve) => {
            for (const bead of this.beads) {
                // The beads fire a window event with payload endOfCycleParams
                // to signal that they have finished one cycle.
                // Whenever that happens, remove 1 star.
                window.addEventListener("message", async (event) => {
                    if (!seenCycles.has(event.data.iterationNum)) {
                        seenCycles.add(event.data.iterationNum);
                        const hasStarsRemaining = await this.countdown.RemoveStar(event.data.playbackRate);
                        if (!hasStarsRemaining) {
                            this.stopAnimations();
                            resolve(0);
                        }
                    }
                });
                bead.animateElement();
            }
            const outcome = await this.StartGameAndWaitForOutcome();
            this.stopAnimations();
            if (outcome == level_pb_js_1.LevelStatus.LOSE) {
                resolve(undefined);
            }
            resolve(this.countdown.numStars);
        });
    }
    async StartGameAndWaitForOutcome() {
        const status = await new Promise(async (resolve) => {
            const status = await this.startBeadAnimationsAndWait();
            switch (status) {
                case level_pb_js_1.BeadSelection.WRONG_GUESS:
                    const hasStarsRemaining = await this.countdown.RemoveStar(
                    /*playbackRate=*/ 1);
                    if (hasStarsRemaining) {
                        resolve(level_pb_js_1.LevelStatus.UNSPECIFIED);
                    }
                    else {
                        resolve(level_pb_js_1.LevelStatus.LOSE);
                    }
                    break;
                case level_pb_js_1.BeadSelection.CORRECT_GUESS:
                    this.countdown.CancelAnimationAndRestoreStar();
                    resolve(level_pb_js_1.LevelStatus.WIN);
                    break;
            }
        });
        switch (status) {
            case level_pb_js_1.LevelStatus.WIN:
                return level_pb_js_1.LevelStatus.WIN;
            case level_pb_js_1.LevelStatus.LOSE:
                return level_pb_js_1.LevelStatus.LOSE;
            case level_pb_js_1.LevelStatus.UNSPECIFIED:
                return this.StartGameAndWaitForOutcome();
            default:
                throw Error("Unknown LevelStatus: " + status);
        }
    }
    AppendPerson(person) {
        const bead = new bead_js_1.BoardBead(person, this.level);
        this.beads.push(bead);
        this.innerContainer.Append(bead);
    }
    AppendAvatarBeads() {
        (0, util_js_1.shuffleArray)(this.beads);
        for (const bead of this.beads) {
            const avatarBead = bead.GetAvatarBead();
            this.avatarBeadsContainer.Append(avatarBead);
        }
    }
    stopAnimations() {
        for (const bead of this.beads) {
            bead.stopAnimation();
        }
    }
    startBeadAnimationsAndWait() {
        return new Promise(async (resolve) => {
            const promises = [];
            for (const bead of this.beads) {
                promises.push(bead.initAndWaitForUserSelection());
            }
            const type = await Promise.any(promises);
            if (type === level_pb_js_1.PersonType.INDIGENOUS) {
                resolve(level_pb_js_1.BeadSelection.CORRECT_GUESS);
            }
            else {
                resolve(level_pb_js_1.BeadSelection.WRONG_GUESS);
            }
        });
    }
    AppendCountDown() {
        const countdown = new countdown_js_1.CountDown();
        this.Append(countdown);
        return countdown;
    }
}
exports.GridInst = GridInst;
