"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScoreBoard = void 0;
const level_pb_js_1 = require(".././protos/level_pb.js");
const constants_js_1 = require("./constants.js");
const util_js_1 = require("./util.js");
const emojis_js_1 = require("../emojis.js");
class ScoreBoard extends util_js_1.AppElement {
    buttonContainer = document.createElement("div");
    storer;
    header = document.createElement("div");
    journeyBoards = new Map();
    constructor(storer) {
        super();
        this.storer = storer;
        this.build();
    }
    async Update(nextJourney) {
        const allHidden = [];
        this.journeyBoards.forEach((journeyBoard) => {
            journeyBoard.Update();
            allHidden.push(journeyBoard.Hide());
        });
        if (nextJourney === undefined) {
            console.warn("Found unexpected undefined nextJourney");
            nextJourney = 1;
        }
        Promise.all(allHidden).then(() => {
            this.journeyBoards.get(nextJourney)?.Show();
        });
        this.element.appendChild(this.buttonContainer);
        this.buttonContainer.classList.add("horizontalChoices");
        this.buttonContainer.classList.add("bottomBar");
    }
    waitforUserSelection(nextJourney) {
        if (nextJourney === undefined) {
            nextJourney = 1;
            console.warn("Found undefined nextJourney");
        }
        this.buttonContainer.textContent = "";
        return new Promise((resolve) => {
            if (this.journeyBoards.get(nextJourney)?.CanAccessNextLevel()) {
                const nextLevelButton = this.generateButton("next");
                this.buttonContainer.appendChild(nextLevelButton);
                nextLevelButton.addEventListener(constants_js_1.MOUSEDOWN, (event) => resolve(level_pb_js_1.NextLevelAction.TRIGGER_NEXT_LEVEL));
            }
            else {
                const restartGame = this.generateButton("Reset");
                this.buttonContainer.appendChild(restartGame);
                restartGame.addEventListener(constants_js_1.MOUSEDOWN, (event) => resolve(level_pb_js_1.NextLevelAction.RESTART_GAME));
                const restartJourney = this.generateButton("Retry");
                this.buttonContainer.appendChild(restartJourney);
                restartJourney.addEventListener(constants_js_1.MOUSEDOWN, (event) => resolve(level_pb_js_1.NextLevelAction.RESTART_JOURNEY));
            }
        });
    }
    build() {
        this.Hide();
        this.element.classList.add("foo");
        this.element.appendChild(this.header);
        this.header.classList.add("flexBox");
        this.header.classList.add("overFlowRow");
        this.header.classList.add("headerOrFooter");
        for (const journey of this.storer.GetJourneys()) {
            if (journey.number === undefined) {
                throw Error("Journey number not defined: " + journey);
            }
            const image = document.createElement("img");
            image.src = journey.symbols[0];
            image.addEventListener(constants_js_1.MOUSEDOWN, (e) => {
                const allHidden = [];
                this.journeyBoards.forEach((journeyBoard) => {
                    journeyBoard.Update();
                    allHidden.push(journeyBoard.Hide());
                });
                Promise.all(allHidden).then(() => {
                    if (journey.number !== undefined) {
                        this.journeyBoards.get(journey.number)?.Show();
                    }
                });
            });
            this.header.appendChild(image);
            const journeyBoard = new JourneyBoard(journey, this.storer);
            this.journeyBoards.set(journey.number, journeyBoard);
            this.Append(journeyBoard);
        }
    }
    generateButton(text) {
        var button = document.createElement("div");
        button.classList.add("selectable");
        button.classList.add("levelAction");
        button.textContent = text;
        return button;
    }
}
exports.ScoreBoard = ScoreBoard;
class JourneyBoard extends util_js_1.AppElement {
    journey;
    levels = new Map();
    starCounter = document.createElement("span");
    starNum = 0;
    constructor(journey, storer) {
        super();
        this.journey = journey;
        this.Hide();
        this.element.classList.add("journeyBoard");
        this.element.classList.add("flexBox");
        this.element.classList.add("overFlowWrap");
        this.starCounter.setAttribute("id", "starCounter");
        this.element.appendChild(this.starCounter);
        for (const level of this.journey.levels) {
            if (level.number === undefined) {
                throw Error("Level number undefined " + level);
            }
            const levelBoard = new LevelBoard(level, storer, journey.number || 1);
            this.levels.set(level.number, levelBoard);
            this.Append(levelBoard);
        }
    }
    async Hide() {
        const keyframes = new KeyframeEffect(this.element, [
            {
                offset: 0,
                opacity: "1",
            },
            {
                offset: 1,
                opacity: "0",
            },
        ], {
            duration: 300,
            fill: "forwards",
        });
        const animation = new Animation(keyframes);
        animation.play();
        await animation.finished;
        this.element.hidden = true;
    }
    async Show() {
        this.element.hidden = false;
        const keyframes = new KeyframeEffect(this.element, [
            {
                offset: 0,
                opacity: "0",
            },
            {
                offset: 1,
                opacity: "1",
            },
        ], {
            duration: 300,
            fill: "forwards",
        });
        const animation = new Animation(keyframes);
        animation.play();
        await animation.finished;
    }
    Update() {
        this.levels.forEach((levelBoard) => levelBoard.Update());
        this.starNum = this.element.getElementsByClassName("active").length;
        this.starCounter.textContent =
            this.starNum.toString() + "/" + this.journey.minimumStarNumber;
    }
    CanAccessNextLevel() {
        if (this.journey.nextLevel === undefined) {
            throw Error("Next level not defined: " + this.journey.nextLevel);
        }
        const lastPlayedLevel = this.levels.get(this.journey.nextLevel);
        if (!lastPlayedLevel) {
            throw Error("Level does not have LevelBoard: " +
                this.levels.get(this.journey.nextLevel));
        }
        if (!lastPlayedLevel.GetNumberOfStars()) {
            // Last played level does not have at least one star
            return false;
        }
        if (this.journey.nextLevel !== this.journey.levels.length) {
            // At least one star and last played lavel is not the last of the journey
            return true;
        }
        if (this.starNum < this.journey.minimumStarNumber) {
            return false;
        }
        return true;
    }
}
class LevelBoard extends util_js_1.AppElement {
    level;
    journeyNumber;
    levelNumber = document.createElement("span");
    levelScore = document.createElement("span");
    storer;
    constructor(level, storer, journeyNumber) {
        super();
        this.storer = storer;
        this.journeyNumber = journeyNumber;
        this.level = level;
        this.levelNumber.classList.add("levelBoard-number");
        this.levelNumber.textContent = this.level.number?.toString() || "";
        this.element.appendChild(this.levelNumber);
        this.setLevelScore();
        this.element.appendChild(this.levelScore);
        this.Update();
    }
    Update() {
        this.level.score = this.storer.getScore(this.journeyNumber, this.level.number || 1);
        const stars = this.levelScore.children;
        if (this.level.score === undefined) {
            for (var i = 0; i < constants_js_1.TOTAL_NUM_STARS; i++) {
                stars[i].classList.remove("active");
                stars[i].classList.add("inactive");
            }
            return;
        }
        for (var i = 0; i < constants_js_1.TOTAL_NUM_STARS; i++) {
            if (i < this.level.score) {
                stars[i].classList.add("active");
                stars[i].classList.remove("inactive");
            }
            else {
                stars[i].classList.remove("active");
                stars[i].classList.add("inactive");
            }
        }
    }
    GetNumberOfStars() {
        return this.GetAsElement().getElementsByClassName("active").length;
    }
    setLevelScore() {
        for (var i = 0; i < constants_js_1.TOTAL_NUM_STARS; i++) {
            const star = document.createElement("img");
            star.classList.add("score");
            star.src = emojis_js_1.STAR;
            this.levelScore.appendChild(star);
        }
    }
}
