"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationElement = void 0;
const util_js_1 = require("./util.js");
const constants_js_1 = require("./constants.js");
class ValidationElement extends util_js_1.AppElement {
    textElement = document.createElement("span");
    constructor() {
        super();
        this.element.setAttribute("id", "validateButtonContainer");
        this.element.classList.add("bottomBar");
        this.element.classList.add("horizontalChoices");
        this.element.appendChild(this.textElement);
    }
    listenForSpinClick() {
        this.element.classList.add("selectable");
        this.textElement.textContent = "spin";
        return new Promise((resolve) => {
            this.element.addEventListener(constants_js_1.MOUSEDOWN, (_) => resolve());
        });
    }
    listenForSpotClick() {
        this.element.classList.add("selectable");
        this.textElement.textContent = "spot";
        return new Promise((resolve) => {
            this.element.addEventListener(constants_js_1.MOUSEDOWN, (event) => resolve());
        });
    }
}
exports.ValidationElement = ValidationElement;
