// @generated by protoc-gen-es v1.10.0 with parameter "keep_empty_files=true,target=js+dts"
// @generated from file frontend/protos/level.proto (syntax proto2)
/* eslint-disable */
// @ts-nocheck

import { proto2 } from "@bufbuild/protobuf";

/**
 * @generated from enum MoveDirection
 */
export const MoveDirection = /*@__PURE__*/ proto2.makeEnum(
  "MoveDirection",
  [
    {no: 0, name: "MOVE_DIRECTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MOVE_DIRECTION_NO_MOVE", localName: "NO_MOVE"},
    {no: 2, name: "MOVE_DIRECTION_NORTH", localName: "NORTH"},
    {no: 3, name: "MOVE_DIRECTION_SOUTH", localName: "SOUTH"},
    {no: 4, name: "MOVE_DIRECTION_WEST", localName: "WEST"},
    {no: 5, name: "MOVE_DIRECTION_EAST", localName: "EAST"},
    {no: 6, name: "MOVE_DIRECTION_SOUTH_EAST", localName: "SOUTH_EAST"},
    {no: 7, name: "MOVE_DIRECTION_SOUTH_WEST", localName: "SOUTH_WEST"},
    {no: 8, name: "MOVE_DIRECTION_NORTH_EAST", localName: "NORTH_EAST"},
    {no: 9, name: "MOVE_DIRECTION_NORTH_WEST", localName: "NORTH_WEST"},
    {no: 10, name: "MOVE_DIRECTION_DOUBLE_NORTH", localName: "DOUBLE_NORTH"},
    {no: 11, name: "MOVE_DIRECTION_DOUBLE_SOUTH", localName: "DOUBLE_SOUTH"},
    {no: 12, name: "MOVE_DIRECTION_DOUBLE_WEST", localName: "DOUBLE_WEST"},
    {no: 13, name: "MOVE_DIRECTION_DOUBLE_EAST", localName: "DOUBLE_EAST"},
  ],
);

/**
 * @generated from enum MoveSpin
 */
export const MoveSpin = /*@__PURE__*/ proto2.makeEnum(
  "MoveSpin",
  [
    {no: 0, name: "MOVE_SPIN_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MOVE_SPIN_NO_SPIN", localName: "NO_SPIN"},
    {no: 2, name: "MOVE_SPIN_HALF_CLOCKWISE", localName: "HALF_CLOCKWISE"},
    {no: 3, name: "MOVE_SPIN_HALF_COUNTER_CLOCKWISE", localName: "HALF_COUNTER_CLOCKWISE"},
  ],
);

/**
 * @generated from enum MoveGrow
 */
export const MoveGrow = /*@__PURE__*/ proto2.makeEnum(
  "MoveGrow",
  [
    {no: 0, name: "MOVE_GROW_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MOVE_GROW_NO_GROW", localName: "NO_GROW"},
    {no: 2, name: "MOVE_GROW_ENLARGE", localName: "ENLARGE"},
    {no: 3, name: "MOVE_GROW_SHRINK", localName: "SHRINK"},
  ],
);

/**
 * @generated from enum PersonType
 */
export const PersonType = /*@__PURE__*/ proto2.makeEnum(
  "PersonType",
  [
    {no: 0, name: "PERSON_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PERSON_TYPE_INDIGENOUS", localName: "INDIGENOUS"},
    {no: 2, name: "PERSON_TYPE_ALIEN", localName: "ALIEN"},
  ],
);

/**
 * @generated from enum Theme
 */
export const Theme = /*@__PURE__*/ proto2.makeEnum(
  "Theme",
  [
    {no: 0, name: "THEME_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "THEME_BEACH", localName: "BEACH"},
    {no: 2, name: "THEME_MOUNTAIN", localName: "MOUNTAIN"},
    {no: 3, name: "THEME_SPACE", localName: "SPACE"},
    {no: 4, name: "THEME_BAKERY", localName: "BAKERY"},
  ],
);

/**
 * @generated from enum LevelStatus
 */
export const LevelStatus = /*@__PURE__*/ proto2.makeEnum(
  "LevelStatus",
  [
    {no: 0, name: "LEVEL_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "LEVEL_STATUS_WIN", localName: "WIN"},
    {no: 2, name: "LEVEL_STATUS_LOSE", localName: "LOSE"},
  ],
);

/**
 * @generated from enum BeadSelection
 */
export const BeadSelection = /*@__PURE__*/ proto2.makeEnum(
  "BeadSelection",
  [
    {no: 0, name: "BEAD_SELECTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "BEAD_SELECTION_CORRECT_GUESS", localName: "CORRECT_GUESS"},
    {no: 2, name: "BEAD_SELECTION_WRONG_GUESS", localName: "WRONG_GUESS"},
  ],
);

/**
 * @generated from enum NextLevelAction
 */
export const NextLevelAction = /*@__PURE__*/ proto2.makeEnum(
  "NextLevelAction",
  [
    {no: 0, name: "NEXT_LEVEL_ACTION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "NEXT_LEVEL_ACTION_RESTART_GAME", localName: "RESTART_GAME"},
    {no: 2, name: "NEXT_LEVEL_ACTION_RESTART_JOURNEY", localName: "RESTART_JOURNEY"},
    {no: 3, name: "NEXT_LEVEL_ACTION_TRIGGER_NEXT_LEVEL", localName: "TRIGGER_NEXT_LEVEL"},
  ],
);

/**
 * @generated from message Game
 */
export const Game = /*@__PURE__*/ proto2.makeMessageType(
  "Game",
  () => [
    { no: 1, name: "journeys", kind: "message", T: Journey, repeated: true },
    { no: 2, name: "next_journey", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message Journey
 */
export const Journey = /*@__PURE__*/ proto2.makeMessageType(
  "Journey",
  () => [
    { no: 1, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "theme", kind: "enum", T: proto2.getEnumType(Theme), opt: true },
    { no: 3, name: "allowed_moves", kind: "message", T: Move, repeated: true },
    { no: 4, name: "levels", kind: "message", T: Level, repeated: true },
    { no: 5, name: "symbols", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "minimum_star_number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 7, name: "next_level", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "num_moves", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 10, name: "num_aliens", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 11, name: "grid", kind: "message", T: Grid, opt: true },
  ],
);

/**
 * @generated from message Level
 */
export const Level = /*@__PURE__*/ proto2.makeMessageType(
  "Level",
  () => [
    { no: 1, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "size", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "num_moves", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "num_aliens", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 5, name: "grid", kind: "message", T: Grid, opt: true },
    { no: 7, name: "time_per_move_ms", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 8, name: "trajectory_iterations_allowed", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 9, name: "score", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message Grid
 */
export const Grid = /*@__PURE__*/ proto2.makeMessageType(
  "Grid",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 3, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 4, name: "aliens", kind: "message", T: Person, repeated: true },
    { no: 5, name: "indigenous", kind: "message", T: Person, opt: true },
  ],
);

/**
 * @generated from message Person
 */
export const Person = /*@__PURE__*/ proto2.makeMessageType(
  "Person",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "position", kind: "message", T: Position, opt: true },
    { no: 4, name: "trajectory", kind: "message", T: Trajectory, opt: true },
    { no: 5, name: "type", kind: "enum", T: proto2.getEnumType(PersonType), opt: true },
  ],
);

/**
 * @generated from message Trajectory
 */
export const Trajectory = /*@__PURE__*/ proto2.makeMessageType(
  "Trajectory",
  () => [
    { no: 1, name: "moves", kind: "message", T: Move, repeated: true },
  ],
);

/**
 * @generated from message Position
 */
export const Position = /*@__PURE__*/ proto2.makeMessageType(
  "Position",
  () => [
    { no: 1, name: "x_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
    { no: 2, name: "y_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message Move
 */
export const Move = /*@__PURE__*/ proto2.makeMessageType(
  "Move",
  () => [
    { no: 1, name: "direction", kind: "enum", T: proto2.getEnumType(MoveDirection), opt: true },
    { no: 2, name: "spin", kind: "enum", T: proto2.getEnumType(MoveSpin), opt: true },
    { no: 3, name: "grow", kind: "enum", T: proto2.getEnumType(MoveGrow), opt: true },
  ],
);

