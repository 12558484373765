"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GAME = void 0;
const level_pb_js_1 = require(".././protos/level_pb.js");
const emoji = __importStar(require("../emojis.js"));
function getDefaultGrid() {
    return new level_pb_js_1.Grid({
        indigenous: new level_pb_js_1.Person({
            trajectory: new level_pb_js_1.Trajectory(),
            type: level_pb_js_1.PersonType.INDIGENOUS,
        }),
    });
}
exports.GAME = new level_pb_js_1.Game({
    journeys: [
        new level_pb_js_1.Journey({
            number: 1,
            allowedMoves: [
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.NORTH }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.SOUTH }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.WEST }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.EAST }),
            ],
            size: 5,
            numMoves: 3,
            numAliens: 3,
            grid: getDefaultGrid(),
            levels: [
                new level_pb_js_1.Level({
                    number: 1,
                    timePerMoveMs: 460,
                }),
                new level_pb_js_1.Level({
                    number: 2,
                    timePerMoveMs: 440,
                }),
                new level_pb_js_1.Level({
                    number: 3,
                    timePerMoveMs: 420,
                }),
                new level_pb_js_1.Level({
                    number: 4,
                    timePerMoveMs: 400,
                }),
                new level_pb_js_1.Level({
                    number: 5,
                    timePerMoveMs: 380,
                }),
                new level_pb_js_1.Level({
                    number: 6,
                    timePerMoveMs: 360,
                }),
                new level_pb_js_1.Level({
                    number: 7,
                    timePerMoveMs: 340,
                }),
                new level_pb_js_1.Level({
                    number: 8,
                    timePerMoveMs: 320,
                }),
                new level_pb_js_1.Level({
                    number: 9,
                    timePerMoveMs: 300,
                }),
            ],
            symbols: [
                emoji.SHORT,
                emoji.BEACH_WITH_UMBRELLA,
                emoji.BIKINI,
                emoji.SUN,
                emoji.SOFT_ICE_CREAM,
                emoji.THONG_SANDAL,
                emoji.DESERT_ISLAND,
                emoji.CORAL,
                emoji.SPIRAL_SHELL,
            ],
            minimumStarNumber: 30,
            nextLevel: 1,
        }),
        new level_pb_js_1.Journey({
            number: 2,
            allowedMoves: [
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.NORTH_EAST }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.NORTH_WEST }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.SOUTH_EAST }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.SOUTH_WEST }),
            ],
            size: 5,
            numMoves: 3,
            numAliens: 4,
            grid: getDefaultGrid(),
            levels: [
                new level_pb_js_1.Level({
                    number: 1,
                    timePerMoveMs: 440,
                }),
                new level_pb_js_1.Level({
                    number: 2,
                    timePerMoveMs: 420,
                }),
                new level_pb_js_1.Level({
                    number: 3,
                    timePerMoveMs: 400,
                }),
                new level_pb_js_1.Level({
                    number: 4,
                    timePerMoveMs: 380,
                }),
                new level_pb_js_1.Level({
                    number: 5,
                    timePerMoveMs: 360,
                }),
                new level_pb_js_1.Level({
                    number: 6,
                    timePerMoveMs: 340,
                }),
                new level_pb_js_1.Level({
                    number: 7,
                    timePerMoveMs: 320,
                }),
                new level_pb_js_1.Level({
                    number: 8,
                    timePerMoveMs: 300,
                }),
                new level_pb_js_1.Level({
                    number: 9,
                    timePerMoveMs: 280,
                }),
            ],
            symbols: [
                emoji.CLOUD_WITH_SNOW,
                emoji.SNOWFLAKE,
                emoji.SNOWMAN_WITHOUT_SNOW,
                emoji.ICE_SKATE,
                emoji.SLED,
            ],
            minimumStarNumber: 30,
            nextLevel: 1,
        }),
        new level_pb_js_1.Journey({
            number: 3,
            allowedMoves: [
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.NORTH }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.SOUTH }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.WEST }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.EAST }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.DOUBLE_NORTH }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.DOUBLE_SOUTH }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.DOUBLE_WEST }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.DOUBLE_EAST }),
            ],
            size: 6,
            numMoves: 3,
            numAliens: 5,
            grid: getDefaultGrid(),
            levels: [
                new level_pb_js_1.Level({
                    number: 1,
                    timePerMoveMs: 420,
                }),
                new level_pb_js_1.Level({
                    number: 2,
                    timePerMoveMs: 400,
                }),
                new level_pb_js_1.Level({
                    number: 3,
                    timePerMoveMs: 380,
                }),
                new level_pb_js_1.Level({
                    number: 4,
                    timePerMoveMs: 360,
                }),
                new level_pb_js_1.Level({
                    number: 5,
                    timePerMoveMs: 340,
                }),
                new level_pb_js_1.Level({
                    number: 6,
                    timePerMoveMs: 320,
                }),
                new level_pb_js_1.Level({
                    number: 7,
                    timePerMoveMs: 300,
                }),
                new level_pb_js_1.Level({
                    number: 8,
                    timePerMoveMs: 280,
                }),
                new level_pb_js_1.Level({
                    number: 9,
                    timePerMoveMs: 260,
                }),
            ],
            symbols: [
                emoji.SATELLITE,
                emoji.WAXING_CRESCENT_MOON,
                emoji.ROCKET,
                emoji.FLYING_SAUCER,
                emoji.ALIEN,
                emoji.RINGED_PLANET,
            ],
            minimumStarNumber: 30,
            nextLevel: 1,
        }),
        new level_pb_js_1.Journey({
            number: 4,
            allowedMoves: [
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.NORTH,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.SOUTH,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.EAST,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.WEST,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.SOUTH,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.EAST,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.WEST,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.NORTH,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
            ],
            size: 5,
            numMoves: 3,
            numAliens: 6,
            grid: getDefaultGrid(),
            levels: [
                new level_pb_js_1.Level({
                    number: 1,
                    timePerMoveMs: 420,
                }),
                new level_pb_js_1.Level({
                    number: 2,
                    timePerMoveMs: 400,
                }),
                new level_pb_js_1.Level({
                    number: 3,
                    timePerMoveMs: 380,
                }),
                new level_pb_js_1.Level({
                    number: 4,
                    timePerMoveMs: 360,
                }),
                new level_pb_js_1.Level({
                    number: 5,
                    timePerMoveMs: 340,
                }),
                new level_pb_js_1.Level({
                    number: 6,
                    timePerMoveMs: 320,
                }),
                new level_pb_js_1.Level({
                    number: 7,
                    timePerMoveMs: 300,
                }),
                new level_pb_js_1.Level({
                    number: 8,
                    timePerMoveMs: 280,
                }),
                new level_pb_js_1.Level({
                    number: 9,
                    timePerMoveMs: 260,
                }),
            ],
            symbols: [
                emoji.CHERRIES,
                emoji.STRAWBERRY,
                emoji.LEMON,
                emoji.PINEAPPLE,
                emoji.WATERMELON,
                emoji.KIWI_FRUIT,
                emoji.BANANA,
                emoji.BLUEBERRIES,
                emoji.LIME,
                emoji.MELON,
            ],
            minimumStarNumber: 30,
            nextLevel: 1,
        }),
        new level_pb_js_1.Journey({
            number: 5,
            allowedMoves: [
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.NORTH,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.SOUTH,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.EAST,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.WEST,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.SOUTH,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.EAST,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.WEST,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.NORTH,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.DOUBLE_NORTH,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.DOUBLE_SOUTH,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.DOUBLE_EAST,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.DOUBLE_WEST,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.DOUBLE_SOUTH,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.DOUBLE_EAST,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.DOUBLE_WEST,
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                }),
                new level_pb_js_1.Move({
                    direction: level_pb_js_1.MoveDirection.DOUBLE_NORTH,
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                }),
            ],
            size: 6,
            numMoves: 3,
            numAliens: 6,
            grid: getDefaultGrid(),
            levels: [
                new level_pb_js_1.Level({
                    number: 1,
                    timePerMoveMs: 420,
                }),
                new level_pb_js_1.Level({
                    number: 2,
                    timePerMoveMs: 400,
                }),
                new level_pb_js_1.Level({
                    number: 3,
                    timePerMoveMs: 380,
                }),
                new level_pb_js_1.Level({
                    number: 4,
                    timePerMoveMs: 360,
                }),
                new level_pb_js_1.Level({
                    number: 5,
                    timePerMoveMs: 340,
                }),
                new level_pb_js_1.Level({
                    number: 6,
                    timePerMoveMs: 320,
                }),
                new level_pb_js_1.Level({
                    number: 7,
                    timePerMoveMs: 300,
                }),
                new level_pb_js_1.Level({
                    number: 8,
                    timePerMoveMs: 280,
                }),
                new level_pb_js_1.Level({
                    number: 9,
                    timePerMoveMs: 260,
                }),
            ],
            symbols: [
                emoji.LOLLIPOP,
                emoji.MOON_CAKE,
                emoji.DOUGHNUT,
                emoji.BIRTHDAY_CAKE,
                emoji.COOKIE,
                emoji.CHOCOLATE_BAR,
                emoji.HONEY_POT,
                emoji.CANDY,
                emoji.ICE_CREAM,
                emoji.FFORTUNE_COOKIE,
            ],
            minimumStarNumber: 30,
            nextLevel: 1,
        }),
        new level_pb_js_1.Journey({
            number: 6,
            allowedMoves: [
                new level_pb_js_1.Move({
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                    grow: level_pb_js_1.MoveGrow.NO_GROW,
                }),
                new level_pb_js_1.Move({
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                    grow: level_pb_js_1.MoveGrow.SHRINK,
                }),
                new level_pb_js_1.Move({
                    spin: level_pb_js_1.MoveSpin.HALF_CLOCKWISE,
                    grow: level_pb_js_1.MoveGrow.ENLARGE,
                }),
                new level_pb_js_1.Move({
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                    grow: level_pb_js_1.MoveGrow.NO_GROW,
                }),
                new level_pb_js_1.Move({
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                    grow: level_pb_js_1.MoveGrow.SHRINK,
                }),
                new level_pb_js_1.Move({
                    spin: level_pb_js_1.MoveSpin.HALF_COUNTER_CLOCKWISE,
                    grow: level_pb_js_1.MoveGrow.ENLARGE,
                }),
            ],
            size: 5,
            numMoves: 3,
            numAliens: 6,
            grid: getDefaultGrid(),
            levels: [
                new level_pb_js_1.Level({
                    number: 1,
                    timePerMoveMs: 400,
                }),
                new level_pb_js_1.Level({
                    number: 2,
                    timePerMoveMs: 380,
                }),
                new level_pb_js_1.Level({
                    number: 3,
                    timePerMoveMs: 360,
                }),
                new level_pb_js_1.Level({
                    number: 4,
                    timePerMoveMs: 340,
                }),
                new level_pb_js_1.Level({
                    number: 5,
                    timePerMoveMs: 320,
                }),
                new level_pb_js_1.Level({
                    number: 6,
                    timePerMoveMs: 300,
                }),
                new level_pb_js_1.Level({
                    number: 7,
                    timePerMoveMs: 280,
                }),
                new level_pb_js_1.Level({
                    number: 8,
                    timePerMoveMs: 260,
                }),
                new level_pb_js_1.Level({
                    number: 9,
                    timePerMoveMs: 240,
                }),
            ],
            symbols: [
                emoji.HOT_PEPPER,
                emoji.EAR_OF_CORN,
                emoji.POTATO,
                emoji.AVOCADO,
                emoji.CARROT,
                emoji.BEANS,
                emoji.EGGPLANT,
                emoji.BROCCOLI,
                emoji.GINGER_ROOT,
                emoji.LAEFY_GREEN
            ],
            minimumStarNumber: 30,
            nextLevel: 1,
        }),
        new level_pb_js_1.Journey({
            number: 7,
            allowedMoves: [
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.NORTH }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.SOUTH }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.WEST }),
                new level_pb_js_1.Move({ direction: level_pb_js_1.MoveDirection.EAST }),
            ],
            size: 10,
            numMoves: 4,
            numAliens: 8,
            grid: getDefaultGrid(),
            levels: [
                new level_pb_js_1.Level({
                    number: 1,
                    timePerMoveMs: 400,
                }),
                new level_pb_js_1.Level({
                    number: 2,
                    timePerMoveMs: 380,
                }),
                new level_pb_js_1.Level({
                    number: 3,
                    timePerMoveMs: 360,
                }),
                new level_pb_js_1.Level({
                    number: 4,
                    timePerMoveMs: 340,
                }),
                new level_pb_js_1.Level({
                    number: 5,
                    timePerMoveMs: 320,
                }),
                new level_pb_js_1.Level({
                    number: 6,
                    timePerMoveMs: 300,
                }),
                new level_pb_js_1.Level({
                    number: 7,
                    timePerMoveMs: 280,
                }),
                new level_pb_js_1.Level({
                    number: 8,
                    timePerMoveMs: 260,
                }),
                new level_pb_js_1.Level({
                    number: 9,
                    timePerMoveMs: 240,
                }),
            ],
            symbols: [
                emoji.FRENCH_FRIES,
                emoji.PIZZA,
                emoji.HOT_DOG,
                emoji.TACO,
                emoji.BURRITO,
                emoji.SANDWICH,
                emoji.HAMBURGER,
                emoji.BACON,
                emoji.POULTRY_LEG,
            ],
            minimumStarNumber: 45,
            nextLevel: 1,
        }),
    ],
    nextJourney: 1,
});
